@import "../../theme/variables";
@import "../../theme/functions/grid";

.icon-button {
  cursor: pointer;
}

.icon {
  position: relative;
  width: grid(4);
  height: grid(4);

  &--secondary {
    fill: $color-text-secondary;
  }

  &--small {
    width: grid(3);
    height: grid(3);
  }

  &--large {
    width: grid(5);
    height: grid(5);
  }

  // make children use all available space
  > * {
    width: 100%;
    height: 100%;
  }
}

.icon-text {
  margin-top: 0.5em;
  text-align: center;
  font-size: grid(2);

  user-select: none;

  &--small {
    font-size: screen(14);
  }

  &--large {
    font-size: screen(20);
  }
}

.badge {
  position: absolute;
  top: grid(-1);
  right: grid(-1);
  width: screen(28);
  height: screen(28);
  border-radius: $radius-infinite;
  background-color: $color-bg-tertiary;
  color: $color-text-secondary;
  font-size: $font-size-small;
}
