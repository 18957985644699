@import "../../theme/variables";
@import "../../theme/functions/grid";

.options {
  margin-left: grid(3);
  padding: grid(1);
  width: grid(6);
  height: grid(6);
  border: 1px solid transparent;
  border-radius: $radius-infinite;
  cursor: pointer;
  transition: background-color ease-in-out $animation-speed-medium, border-color ease-in-out $animation-speed-medium;
  svg {
    width: 100%;
    height: 100%;
    fill: $color-text-darker;
  }

  &:hover {
    border-color: $color-bg-divider;
  }

  &--open {
    border-color: $color-bg-divider;
    background-color: $color-bg-primary;
  }
}
