@import "../../theme/variables";
@import "../../theme/functions/grid";

.menu {
  position: relative;
  z-index: 3;
  width: grid(35);
  background-color: $color-bg-primary;
}

.content {
  border-top: 2px solid $color-bg-divider;
  border-left: 2px solid $color-bg-divider;
  border-top-left-radius: $radius-small;
  background-color: $color-bg-content;
}
