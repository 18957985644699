@import "../../theme/variables";

.panel {
  border: 1px solid $color-bg-divider;
  border-radius: $radius-medium;
  background-color: $color-bg-primary;

  &--raised {
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  }
}
