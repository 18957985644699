@import "../../theme/variables";
@import "../../theme/functions/grid";

.button-group {
  border: 1px solid $color-bg-divider;
  border-radius: $radius-medium;
  &--equal-width {
    .button-group-button {
      flex-basis: 50%;
    }
  }

  &--secondary {
    border: none;
  }

  .button-group-button {
    flex: 1 1 auto;
    padding: 0 grid(3);
    height: grid(6);
    border-right: 1px solid $color-bg-divider;
    background-color: $color-bg-primary;
    color: $color-text-darker;
    cursor: pointer;
    transition: background-color $animation-speed-medium ease-in-out;

    user-select: none;

    &:last-child {
      border-right: none;
    }

    &:hover {
      background-color: $color-bg-light;
    }

    &--secondary {
      padding: 0 grid(1);
      height: grid(4);
      border: none;
      background-color: transparent;
      font-size: $font-size-small;

      .children {
        text-decoration: underline;
      }

      &:hover {
        background-color: transparent;
      }
    }

    &--danger {
      background-color: $color-bg-danger;
      color: $color-text-secondary;

      &:hover {
        background-color: darken($color-bg-danger, $color-change-percent);
      }
    }

    &--loading {
      .children {
        opacity: 0;
      }

      .loading {
        opacity: 1;
      }
    }
  }
}

.children,
.loading {
  opacity: 1;
  transition: opacity $animation-speed-medium ease-in-out;
}

.loading {
  opacity: 0;
}

.leading-icon {
  margin-right: grid(2);
  width: 1em;
  height: 1em;

  // make children use all available space
  > * {
    width: 100%;
    height: 100%;
  }
}
