@import "../../theme/functions/grid";

.logo {
  height: grid(12);
  background: url("../../theme/images/logo.png") no-repeat center / contain;

  &--left {
    background-position: left center;
  }

  &--right {
    background-position: right center;
  }

  &--small {
    height: grid(8);
  }
}
