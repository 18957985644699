// https://github.com/animate-css/animate.css/blob/main/source/fading_entrances/fadeInDown.css
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
