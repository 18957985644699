@import "../../theme/variables";
@import "../../theme/functions/grid";

.avatar {
  align-self: baseline;
  width: grid(10);
  height: grid(10);
  border-radius: $radius-infinite;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &--small {
    width: grid(6);
    height: grid(6);
  }

  &--large {
    width: grid(14);
    height: grid(14);
  }
}
