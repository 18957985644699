@import "../../theme/variables";
@import "../../theme/mixins/shake";
@import "../../theme/functions/grid";

.markdown-field {
  &--has-value .border,
  &--has-focus .border {
    background-color: $color-text-darker;
  }

  &--has-value .label {
    border: 1px solid green;
  }

  &--has-error {
    @include shake();

    .border {
      background-color: $color-error !important;
    }

    .error {
      opacity: 1;
    }
  }
}

.label {
  margin-bottom: grid(1);
  color: $color-text-primary;
  font-size: $font-size-tiny;
}

.error {
  display: flex;
  flex-direction: column;
  min-height: $field-error-height;
  color: $color-error;
  font-size: $font-size-small;
  opacity: 0;
  transition: opacity $animation-speed-medium ease-in-out;
}
