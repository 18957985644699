@import "../../theme/variables";
@import "../../theme/functions/grid";

.tag {
  padding: grid(0.5) grid(1);
  background-color: $color-bg-tertiary;
  color: $color-text-secondary;
  white-space: nowrap;

  &--tiny {
    padding: grid(0.5) grid(0.5);
    font-size: $font-size-tiny;
  }

  &--small {
    font-size: $font-size-small;
  }

  &--large {
    padding: grid(1) grid(4);
    font-size: $font-size-large;
  }

  &--secondary {
    background-color: $color-text-lighter;
  }
}
