@import "../../theme/variables";
@import "../../theme/mixins/scrollbar";

.flex {
  display: flex;
  overflow: hidden;
  flex-basis: auto;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  flex-wrap: nowrap;
  vertical-align: top;
  transition: color $animation-speed-medium ease-in-out;
  svg {
    transition: fill $animation-speed-medium ease-in-out;
  }

  &--ignore-pointer-events {
    pointer-events: none;
  }

  &--secondary {
    color: $color-text-secondary;

    svg {
      fill: $color-text-secondary;
    }

    a {
      color: $color-text-secondary;
    }
  }

  &--alternate {
    font-family: $font-alternate;
  }

  &--row {
    flex-direction: row;
  }

  &--column {
    flex-direction: column;
  }

  &--inline {
    display: inline-flex;
  }

  &--nowrap {
    overflow: hidden;
    width: 100%;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &--overflow {
    overflow: visible;
  }

  &--expanded {
    // TODO: needed for Safari (carousel does not get height in expanded containers etc)
    flex-basis: 100%;
    flex-grow: 1;
  }

  &--unshrinkable {
    flex-shrink: 0;
  }

  &--shrinkable {
    flex-shrink: 1;
  }

  &--limited {
    flex-grow: 1;
    flex-shrink: 1;
    max-width: $layout-limited-width;
  }

  &--scrollable {
    @include scrollbar();

    overflow-y: auto;

    overscroll-behavior: contain;
  }

  &--center-flex {
    justify-content: center;
    align-items: center;
  }

  &--center-text {
    text-align: center;
  }

  &--cover {
    width: 100%;
    height: 100%;
  }

  &--overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &--relative {
    position: relative;
  }

  &--pan-x {
    touch-action: pan-x;
  }

  &--pan-y {
    touch-action: pan-y;
  }

  &--paragraph,
  &--span {
    // dont use flex for text
    display: unset;
    margin: 0;
    line-height: 1.5;
  }

  &--tiny {
    font-size: $font-size-tiny;
  }

  &--small {
    font-size: $font-size-small;
  }

  &--large {
    font-size: $font-size-large;
  }

  &--extra-large {
    font-size: $font-size-large;
  }

  &--compact {
    line-height: 1.2;
  }

  &--strong {
    font-weight: bold;
  }

  &--light {
    font-weight: 300;
  }

  &--thin {
    font-weight: 100;
  }

  &--italic {
    font-style: italic;
  }

  &--lighter {
    color: $color-text-lighter;
  }

  &--darker {
    color: $color-text-darker;

    a {
      color: $color-text-darker;
    }
  }

  // for some reason inline styles seem to mess up animation performance
  &--justify-content-flex-start {
    justify-content: flex-start;
  }
  &--justify-content-flex-end {
    justify-content: flex-end;
  }
  &--justify-content-center {
    justify-content: center;
  }
  &--justify-content-baseline {
    justify-content: baseline;
  }
  &--justify-content-stretch {
    justify-content: stretch;
  }
  &--justify-content-space-between {
    justify-content: space-between;
  }
  &--justify-content-space-evenly {
    justify-content: space-evenly;
  }

  &--align-items-flex-start {
    align-items: flex-start;
  }
  &--align-items-flex-end {
    align-items: flex-end;
  }
  &--align-items-center {
    align-items: center;
  }
  &--align-items-baseline {
    align-items: baseline;
  }
  &--align-items-stretch {
    align-items: stretch;
  }
  &--align-items-space-between {
    align-items: space-between;
  }
  &--align-items-space-evenly {
    align-items: space-evenly;
  }

  &--hide-on-small-screens {
    @media (max-height: 500px) {
      display: none;
    }
  }

  &--has-click-handler {
    cursor: pointer;
    pointer-events: all;
  }

  &--debug {
    outline: 1px solid change-color($color: $color-debug-1, $alpha: 0.5);
    outline-offset: -1px;
    background-color: $color-debug-1;
  }

  &--debug2 {
    outline: 1px solid change-color($color: $color-debug-2, $alpha: 0.5);
    outline-offset: -1px;
    background-color: $color-debug-2;
  }

  &--debug3 {
    outline: 1px solid change-color($color: $color-debug-3, $alpha: 0.5);
    outline-offset: -1px;
    background-color: $color-debug-3;
  }
}
