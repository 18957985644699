@import "../../theme/variables";
@import "../../theme/functions/grid";
@import "../../theme/functions/screen";

.actions-dropdown {
  &--open {
    .icon {
      border-color: $color-text-lighter;

      svg {
        fill: $color-text-darker;
      }
    }
  }

  &--disabled {
    .icon {
      border-color: transparent;
      cursor: not-allowed;

      svg {
        fill: $color-text-lighter;
      }

      &:hover {
        border-color: transparent;
      }
    }
  }
}

.icon {
  padding: screen(4);
  width: grid(4);
  height: grid(4);
  border: 1px solid transparent;
  border-radius: $radius-infinite;
  background-color: $color-bg-primary;
  cursor: pointer;
  transition: background-color ease-in-out $animation-speed-medium, border-color ease-in-out $animation-speed-medium;

  svg {
    width: 100%;
    height: 100%;
    fill: $color-text-primary;
  }

  &:hover {
    border-color: $color-text-lighter;
  }
}
