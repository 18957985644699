@import "../../theme/variables";

.title {
  color: $color-text-darker;
  font-weight: 300;
  font-size: $font-size-large;

  a {
    text-decoration: none;
  }

  &--sub {
    color: $color-text-primary;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 500;
    font-size: $font-size-tiny;
  }
}
