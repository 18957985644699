@import "../../theme/functions/grid";
@import "../../theme/variables";

.score-modifier {
  width: grid(4);
  height: grid(4);
  color: #000000;
  font-weight: bold;
}

// make first and last item rounded corners
.score-modifiers {
  div:first-child {
    border-radius: $radius-small 0 0 $radius-small;
  }

  div:last-child {
    border-radius: 0 $radius-small $radius-small 0;
  }
}
