@import "../../theme/variables";
@import "../../theme/functions/grid";

.wrap {
  overflow: visible;
  justify-content: center;

  user-select: none;
  // background-color: $color-debug-3;

  &--sticky {
    position: sticky;
    bottom: 0;

    .pagination {
      margin: grid(3) 0;
    }
  }
}

.pagination {
  align-items: stretch;
  height: grid(6);
  border-radius: $radius-infinite;
  background-color: $color-bg-primary;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.page {
  justify-content: center;
  align-items: center;
  padding: 0 grid(2);
  min-width: grid(8);
  background-color: transparent;
  color: $color-text-lighter;
  font-weight: 300;
  transition: background-color $animation-speed-medium ease-in-out, color $animation-speed-medium ease-in-out;

  &--first {
    padding-left: grid(3);
  }

  &--last {
    padding-right: grid(3);
  }

  &--current {
    background-color: $color-bg-light;
    font-weight: 500;
  }

  &--clickable {
    color: $color-text-primary;
    cursor: pointer;

    &:hover {
      background-color: $color-bg-light;
    }
  }
}
