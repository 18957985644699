@import "../../theme/variables";
@import "../../theme/keyframes/fadeInDown";
@import "../../theme/functions/grid";

.view {
  background: $color-bg-light url("../../theme/images/body-bg.png") no-repeat;
  background-position: center;
  background-size: contain;
}

.panel {
  width: grid(50);
  animation: fadeInDown 500ms;
}
