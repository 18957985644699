@import "../../theme/variables";
@import "../../theme/functions/grid";
@import "../../theme/keyframes/spin";

.loading {
  position: relative;
  width: grid(4);
  height: grid(4);

  svg {
    width: 100%;
    height: 100%;
  }

  &--size-small {
    width: grid(3);
    height: grid(3);
  }

  &--size-large {
    width: grid(6);
    height: grid(6);
  }
}

.loading-indicator {
  animation-name: spin;
  animation-duration: 1s;
  animation-timing-function: steps(12);
  animation-iteration-count: infinite;
}
