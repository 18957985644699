@import "../../theme/functions/grid";
@import "../../theme/variables";

.text-button {
  height: grid(5);
  text-decoration: underline;
  cursor: pointer;

  user-select: none;

  &--without-underline {
    text-decoration: none;
  }

  .children {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
  }

  .icon {
    width: 1em;
    height: 1em;

    &--leading {
      margin-right: grid(1);
    }

    &--trailing {
      margin-left: grid(1);
    }

    // make children use all available space
    > * {
      width: 100%;
      height: 100%;
    }
  }
}
