@import "../../theme/variables";

.markdown {
  p,
  ol,
  ul {
    font-size: $font-size-small;
  }

  ol,
  ul,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 0.5em 0;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 1em;
  }

  h1 {
    color: $color-text-darker;
    font-size: $font-size-medium;
  }

  h2 {
    color: $color-text-primary;
    font-size: $font-size-small;
  }

  h3,
  h4,
  h5,
  h6 {
    color: $color-text-lighter;
    font-size: $font-size-small;
  }
}
