@import "../../theme/variables";
@import "../../theme/keyframes/fadeInDown";
@import "../../theme/functions/grid";

.content {
  border-top: 2px solid $color-bg-divider;

// background-color: $color-bg-content;
  background: $color-bg-light url("../../theme/images/body-bg.png") no-repeat;
  background-position: center;
  background-size: contain;
}

.panel {
  width: grid(60);
  animation: fadeInDown 500ms;
}
