@import "../../theme/functions/grid";

.alert {
  padding: grid(2);
  border-radius: 1rem;

  &--error {
    border-color: #f5c6cb;
    background-color: #f8d7da;
    color: #721c24;
  }

  &--warning {
    border-color: #ffeeba;
    background-color: #fff3cd;
    color: #856404;
  }

  &--info {
    border-color: #bee5eb;
    background-color: #d1ecf1;
    color: #0c5460;
  }

  &--success {
    border-color: #c3e6cb;
    background-color: #d4edda;
    color: #155724;
  }
}
