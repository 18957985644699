// @keyframes shake {
//   0%,
//   100% {
//     transform: translate3d(0, 0, 0);
//   }
//   20%,
//   60% {
//     transform: translate3d(grid(1), 0, 0);
//   }
//   40%,
//   80% {
//     transform: translate3d(grid(-1), 0, 0);
//   }
// }

// only shakes to the right (as left side is likely hidden from overflow)
@keyframes shake {
  0%,
  40%,
  80%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  20%,
  60% {
    transform: translate3d(grid(2), 0, 0);
  }
}
