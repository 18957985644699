@import "../../theme/variables";
@import "../../theme/functions/grid";
@import "../../theme/functions/screen";

.checkbox {
  &--active {
    .indicator {
      background-color: $color-bg-tertiary;
      transform: scale(1, 1);
    }
  }

  &--radio {
    .box {
      border-radius: $radius-infinite;
    }

    .indicator {
      border-radius: $radius-infinite;
    }
  }

  &--with-children {
    padding: grid(1) 0;
  }
}

.box {
  padding: grid(0.25);
  width: grid(2);
  height: grid(2);
  border: screen(2) solid $color-text-primary;
  border-radius: 3px;
}

.indicator {
  width: 100%;
  height: 100%;
  border-radius: screen(2);
  transition: background-color $animation-speed-fast ease-in-out, transform $animation-speed-fast ease-in-out;
  transform: scale(0.5, 0.5);
}

.children {
  margin-left: grid(1);
  font-size: $font-size-small;
  line-height: 1em;

  user-select: none;
}
