@import "../../theme/variables";
@import "../../theme/functions/grid";
@import "../../theme/functions/screen";
@import "../../theme/keyframes/stretchVertical";

.menu {
  // overflow: hidden;
  // overflow-x: visible !important;
  // overflow-y: hidden;
  padding-top: grid(2);
}

.group {
  margin-top: grid(3);
  padding: 0 grid(3);
  text-transform: uppercase;
  font-weight: 100;
  font-size: $font-size-small;

  &:first-child {
    margin-top: 0;
  }
}

.item {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  margin: grid(1) 0;
  padding: 0 grid(4);
  height: grid(6);
  color: $color-text-primary;
  text-decoration: none;
  white-space: nowrap;
  font-weight: 400;
  font-size: $font-size-medium;
  cursor: pointer;

  &:before {
    position: absolute;
    right: grid(-1);
    display: block;
    width: grid(2);
    height: grid(2);
    border-bottom: 2px solid $color-bg-divider;
    border-left: 2px solid $color-bg-divider;
    border-bottom-left-radius: $radius-small;
    background-color: $color-bg-divider;
    content: "";
    opacity: 0;
    transition: opacity $animation-speed-fast ease-in-out;
    transform: rotate(45deg);
  }

  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: screen(-5);
    display: block;
    width: screen(10);
    border-radius: $radius-infinite;
    background-color: transparent;
    content: "";
    transition: background-color ease-in-out $animation-speed-fast;
  }

  svg {
    margin-right: grid(1);
    height: grid(4);
    fill: $color-text-primary;

    * {
      stroke: $color-text-primary;
    }
  }

  &:hover {
    color: $color-text-darker;

    &:after {
      background-color: $color-text-lighter;
      animation: stretchVertical 500ms forwards;
    }
  }

  &--active {
    // background-color: rgba(0, 0, 0, 0.03);
    color: $color-text-tertiary;

    // arrow pointing left for active item
    &:before {
      opacity: 1;
    }

    &:after {
      background-color: $color-bg-tertiary !important;
      animation: stretchVertical 500ms forwards;
    }

    &:hover {
      color: $color-bg-tertiary;
    }

    svg {
      fill: $color-text-tertiary;

      * {
        stroke: $color-text-tertiary;
      }
    }
  }

  // &--not-implemented {
  //   color: #999999;

  //   svg {
  //     fill: #999999;
  //   }
  // }
}

.show-changelog {
  padding: grid(1);
  color: #cccccc;
  text-align: center;
  font-size: screen(14);
}
