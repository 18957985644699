@import "../../theme/variables";
@import "../../theme/functions/grid";
@import "../../theme/keyframes/fadeInDown";
@import "../../theme/keyframes/fadeOutDown";

.dropdown-menu {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
  min-width: grid(20);
  opacity: 0;

  &--open {
    // opacity: 1;
    animation: fadeInDown 500ms forwards;
  }

  &--closing {
    // opacity: 1;
    animation: fadeOutDown 500ms forwards;
  }

  &--closed {
    display: none;
  }
}

.spacer {
  height: grid(5);
}

.contents {
  border: 1px solid $color-bg-divider;
  border-radius: $radius-small;
  background-color: $color-bg-primary;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.dropdown-item {
  padding: grid(1) grid(2);
  height: grid(5);
  border-bottom: 1px solid $color-bg-divider;
  white-space: nowrap;
  font-weight: 500;
  font-size: $font-size-small;
  cursor: pointer;
  transition: background-color ease-in-out $animation-speed-fast;

  .children,
  .loading {
    opacity: 1;
    transition: opacity $animation-speed-medium ease-in-out;
  }

  .loading {
    opacity: 0;
  }

  &--loading {
    cursor: auto;
  }

  &:hover {
    background-color: #fcfcfcfc;
    color: $color-text-darker;

    .dropdown-item-leading svg,
    .dropdown-item-trailing svg {
      fill: $color-text-darker;

      stroke: $color-text-darker;
    }
  }

  &:last-child {
    border-bottom: none;
  }
}

.dropdown-item-leading,
.dropdown-item-trailing {
  svg {
    width: grid(3);
    height: grid(3);
    fill: $color-text-primary;
    transition: fill ease-in-out $animation-speed-fast, stroke ease-in-out $animation-speed-fast;

    stroke: $color-text-primary;
  }
}

.dropdown-item-leading {
  margin-right: grid(1);
}

.dropdown-item-trailing {
  margin-left: grid(3);
}
