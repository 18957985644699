@import "../../theme/variables";
@import "../../theme/functions/grid";

.header {
  padding: grid(1) grid(2);
  height: grid(10);

// border-bottom: 1px solid $color-bg-divider;
  background-color: $color-bg-primary;
}

.logo {
  padding-left: grid(10);
  height: grid(8);
  background-image: url("../../theme/images/logo.png");
  background-position: left center;
  background-size: grid(8);
  background-repeat: no-repeat;
  color: #ab5c38;
  font-weight: 100;
  font-size: $font-size-large;
}
