@import "../variables";

@mixin scrollbar($size: 8px, $foreground-color: $color-text-lighter, $background-color: transparent) {
  // for chrome
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: $size * 0.5;
    background: $foreground-color;
  }

  &::-webkit-scrollbar-track {
    margin-top: $radius-medium;
    margin-bottom: $radius-medium;
    background: $background-color;
  }

  // for ie
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}
