@import "../../theme/variables";
@import "../../theme/functions/grid";
@import "../../theme/mixins/shake";

.field {
  position: relative;
  justify-content: center;
  padding-top: $field-label-padding;
  color: $color-text-primary;
  font-size: $font-size-small;

  &--has-value {
    color: $color-text-darker;

    .label {
      color: $color-text-darker;
      transform: translateY(-$field-input-height * 0.5) translateX(0) scale($field-active-label-scale);
    }

    .border {
      background-color: $color-text-darker;
    }

    .value {
      opacity: 1;
    }

    .clear-icon {
      opacity: 1;
    }
  }

  &--has-error {
    @include shake();

    .border {
      background-color: $color-error !important;
    }

    .error {
      opacity: 1;
    }
  }
}

.value {
  flex-grow: 1;
  justify-content: center;
  height: $field-input-height;
  opacity: 0;
  transition: opacity $animation-speed-medium ease-in-out;
}

.label {
  position: absolute;
  top: $field-label-padding;
  left: 0;
  display: flex;
  align-items: center;
  height: $field-input-height;
  color: $color-text-primary;
  font-size: $font-size-small;
  transition: transform $animation-speed-medium ease-in-out, color $animation-speed-medium ease-in-out;
  transform: translateY(0);
  transform-origin: center left;
}

.border {
  width: 100%;
  height: $field-border-size;
  background-color: $color-text-primary;
  transition: background-color $animation-speed-medium ease-in-out, transform $animation-speed-medium ease-in-out;
}

.clear-icon {
  width: $field-icon-width;
  height: $field-input-height;
  opacity: 0;
  cursor: pointer;
  transition: opacity $animation-speed-fast ease-in-out !important;
}

.error {
  display: flex;
  flex-direction: column;
  min-height: $field-error-height;
  color: $color-error;
  font-size: $font-size-small;
  opacity: 0;
  transition: opacity $animation-speed-medium ease-in-out;
}
