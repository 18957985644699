@import "../../theme/variables";
@import "../../theme/functions/grid";

.name-value-pair {
  padding: grid(1) 0;
  border-top: 1px solid $color-bg-divider;
  font-weight: 300;

  &:first-of-type {
    margin-top: 0;
    border-top: none !important;
  }

  pre {
    font-size: $font-size-small;
  }
}
