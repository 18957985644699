@import "../../theme/variables";
@import "../../theme/functions/grid";
@import "../../theme/keyframes/fadeInDown";
@import "../../theme/keyframes/fadeOutUp";

.toast {
  position: absolute;
  top: grid(1);
  right: grid(60);
  left: grid(35);
  justify-content: center;
  align-items: center;
  height: grid(8);
  border: 1px solid #dddddd;
  border-radius: $radius-medium;
  background-color: #cccccc;
  color: $color-text-darker;

  &--success {
    border: 1px solid #c5e1cc;
    background-color: #eaf7ee;
  }

  &--warning {
    border: 1px solid #f1dec2;
    background-color: #fef7ea;
  }

  &--error {
    border: 1px solid #e9c6bd;
    background-color: #fcede9;
  }

  &--open {
    animation: fadeInDown 500ms forwards;
  }

  &--closing {
    animation: fadeOutUp 500ms forwards;
  }

  &--closed {
    display: none;
  }
}

.status-icon,
.close-icon {
  padding: grid(2);
  width: grid(8);
  height: grid(8);
}

